@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul {
    display: block;
    list-style-type: revert;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  ol {
    display: block;
    list-style-type: revert;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
}

html,
body {
  @apply bg-background-light;

  font-family: 'Noto Sans', 'Noto Sans TC', sans-serif;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
