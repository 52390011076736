.popup_confirmation_popup {
  border-radius: 0.625rem !important;
  box-shadow: 0 0 0.6875rem 0 rgba(14, 16, 16, 0.2) !important;
  padding: 0 0 1.5rem 0 !important;
  max-width: 26.25rem !important;
  transition: max-width 0.2s ease-in-out;
  width: 100% !important;
}

@media (max-width: 37.4375rem) {
  .popup_confirmation_popup {
    max-width: 18.4375rem !important;
  }
}

.popup_confirmation_icon {
  border: none !important;
  margin: 0px auto 0px !important;
  height: 3.75rem !important;
  width: 3.75rem !important;
  padding-top: 1.5rem !important;
}

.popup_confirmation_container {
  background: rgba(0, 0, 0, 0.2) !important;
  padding: 1.25rem !important;
}

.popup_confirmation_html_container {
  font-family: NotoSansCJKtc, 'Noto Sans TC', sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.0731rem !important;
  text-align: center !important;
  color: #7c8282 !important;
  margin: 0.5rem 0rem 0rem !important;
  max-height: 250px;
  overflow-y: auto;
  padding: 0 1.5rem !important;
  white-space: pre-wrap !important;
}

.popup_confirmation_html_container::-webkit-scrollbar {
  display: none;
}

.popup_confirmation_title {
  font-family: NotoSansCJKtc, 'Noto Sans TC', sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.125rem !important;
  text-align: center !important;
  color: #383b3d !important;
  padding-top: 0.75rem !important;
}

.popup_confirmation_actions {
  flex-direction: row-reverse !important;
  margin-top: 0.75rem !important;
  flex-wrap: initial !important;
  width: 100% !important;
}

.popup_confirmation_confirm_button {
  min-height: 2.5rem !important;
  border-radius: 0.5rem !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.popup_confirmation_cancel_button {
  min-height: 2.5rem !important;
  border-radius: 0.5rem !important;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.popup_confirmation_deny_button {
  min-height: 2.5rem !important;
  border-radius: 0.5rem !important;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.swal2-image {
  margin: 0 auto 1em;
  border-radius: 0.625rem 0.625rem 0 0 !important;
}
